<template>
  <div class="box">
    {{$t("message.wait")}}...
  </div>
</template>

<style scoped>
.box {
  flex: 1;
  font-size: 3vw;
  margin-top: 30vh;
}
</style>