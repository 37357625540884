<template>
  <div class="box">
    <div class="carousel_box">
      <div class="mask">
        <div class="cont">
          <el-text class="title">
            {{ $t("message.name") }}
          </el-text>
          <el-text class="text">
            {{ $t("message.compLang.slogan") }}
          </el-text>
          <el-popover placement="top" :width="175">
            <template #reference>
              <el-button class="btn">{{ $t("message.compLang.btnText") }}</el-button>
            </template>
            <template #default>
              <img style="width: 150px;" src="@/assets/contact.png" alt="">
            </template>
          </el-popover>
        </div>
      </div>
      <el-carousel class="carousel" :interval="4000" type="card" :height="height+'px'" arrow="never">
        <el-carousel-item class="carousel_item" v-for="(item,index) in carList" :key="index">
          <img :src="item" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="box_body" :style="bodyStyle">
      <div class="item" v-for="(item,index) in moduleList" :key="index">
        <img :src="item.bgImg" alt="">
        <div class="item_cont">
          <el-text class="title" :style="{color: item.contColor}">{{ $t(item.title) }}</el-text>
          <el-text class="cont" :style="{color: item.contColor}">{{ $t(item.cont) }}</el-text>
          <el-text class="link" :underline="false" :style="{color: item.color}" @click="jump(index)">
            {{ $t("message.more") }}
            <el-icon style="vertical-align: 0">
              <Right />
            </el-icon>
          </el-text>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const { proxy } = getCurrentInstance();
const screenWidth = ref(document.documentElement.clientWidth);
const isPhone = screenWidth.value < 993;
let bodyStyle = ref({
  padding: "2vw 20vw",
});
let height = ref(window.innerWidth / (1440 / 700));
let carList = ref([
  require("@/assets/carItme_1.png"),
  require("@/assets/carItme_2.png"),
  require("@/assets/carItme_3.png"),
]);
onMounted(() => {
  if (isPhone) {
    bodyStyle.value = {
      padding: "2vw 2vw",
    };
  }
  proxy.$bus.emit("jump", 0);
});
let moduleList = ref([
  {
    bgImg: require("@/assets/module_1.png"),
    title: "message.homeLang.menuItem1",
    cont: "message.compLang.menuItem1Cont",
  },
  {
    bgImg: require("@/assets/module_2.png"),
    title: "message.homeLang.menuItem2",
    cont: "message.compLang.menuItem2Cont",
    contColor: "#1D1D1F",
  },
  {
    bgImg: require("@/assets/module_3.png"),
    title: "message.homeLang.menuItem3",
    cont: "message.compLang.menuItem3Cont",
    color: "#fff",
  },
  {
    bgImg: require("@/assets/module_4.png"),
    title: "message.compLang.newMenuItem4",
    cont: "message.compLang.menuItem4Cont",
  },
]);

const jump = (index: number) => {
  proxy.$bus.emit("jump", index + 1);
  router.push({ path: "wait" });
};
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  height: auto;
  .carousel {
    background-image: url("@/assets/carouselBgImg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 0;
    &_item {
      margin-top: 4vw;
      img {
        width: 18vw;
        // width: 100%;
        object-fit: cover;
      }
      ::v-deep(.el-carousel__mask) {
        display: none;
      }
    }
  }
}
.carousel_box {
  position: relative;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 99.5%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    .cont {
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 4.5vw;
        color: #ffffff;
      }
      .text {
        margin-top: 7%;
        width: 39vw;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 1.7vw;
        color: #ffffff;
      }
      .btn {
        margin-top: 5%;
        width: 13vw;
        height: 3vw;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 1.5vw;
        color: #32a4f9;
      }
    }
  }
}
::v-deep(.el-carousel__indicators) {
  z-index: 99999;
}

.box_body {
  width: 100%;
  height: auto;
  display: grid;
  // margin-top: 2vw;
  grid-template-columns: repeat(2, 1fr); /* 两列 */
  grid-gap: 2vw; /* 格子间隔 */
  box-sizing: border-box;
  .item {
    // width: 50%;
    // height: auto;
    background: #000;
    // margin-top: 30px;
    // margin-right: 30px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    &_cont {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: PingFang SC, PingFang SC;
      .title {
        font-weight: 600;
        font-size: 2vw;
        color: #ffffff;
        margin-top: 10%;
      }
      .cont {
        font-weight: 400;
        font-size: 1vw;
        color: #ffffff;
        margin-top: 5%;
      }
      .link {
        font-weight: 600;
        font-size: 0.7vw;
        color: #2195e7;
        margin-top: 5%;
        display: flex;
        align-items: center;
      }
    }
  }
  .item:nth-child(2n) {
    margin-right: 0;
  }
}
</style>
