<template>
  <div class="body">
    <el-container style="min-height: 100%;">
      <el-header style="padding: 0;" :style="headerStyle" class="header">
        <el-menu :unique-opened="true" mode="horizontal" background-color="#222222" text-color="#fff" :ellipsis="false" style="border: none;" :router="true" :default-active="selectInx" @select="selectMenu">
          <el-menu-item v-for="(item,index) in menuList" :key="index" :index="index+''" :disabled="item.disabled" :style="item.style" :route="item.path">
            <div v-if="item.type == 'logo'" :class="item.type">
              <img :src="item.src" style="width: 4vw;object-fit: cover;margin-left: 1vw;" alt="">
              <span>{{ $t("message.name")}}</span>
            </div>
            <div v-else-if="item.type == 'item'" :class="item.type">
              <span>{{ $t(item.title) }}</span>
            </div>
          </el-menu-item>
        </el-menu>
        <div class="header_left">
          <el-dropdown trigger="click" @command="setLang">
            <span class="el-dropdown-link">
              {{ $t("message.lang")}}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="(item,index) in langList" :key="index" :command="index">{{item.value}}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-main style="padding: 0;overflow: hidden;display: flex;">
        <router-view />
      </el-main>
      <!-- eslint-disable -->
      <el-footer class="footer" style="padding: 0;margin-top: 10%;background-color: #222222;">
        <img src="@/assets/qrcode.png" alt="">
        <el-text class="text">© 2024 新疆准东经济技术开发区准达科技有限公司 版权所有 地址：新疆昌吉回族自治州准东经济技术开发区五彩湾新城五彩路101号孵化基地联合办公南区-42号（五彩湾） 新ICP备2024006015号-1</el-text>
      </el-footer>
    </el-container>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
import { ref, getCurrentInstance, onMounted } from "vue";
const { proxy } = getCurrentInstance();
let langList = ref([
  { value: "中文简体", lang: "zh" },
  { value: "English", lang: "en" },
]);
let menuList = ref([
  {
    type: "logo",
    src: require("@/assets/logo.png"),
    disabled: false,
    style: {
      opacity: 1,
    },
    path: "/",
  },
  {
    type: "item",
    title: "message.homeLang.menuItem1",
    path: "/wait",
  },
  {
    type: "item",
    title: "message.homeLang.menuItem2",
    path: "/wait",
  },
  {
    type: "item",
    title: "message.homeLang.menuItem3",
    path: "/wait",
  },
  {
    type: "item",
    title: "message.homeLang.menuItem4",
    path: "/wait",
  },
]);
let selectInx = ref("0");
let headerStyle = ref({});
const screenWidth = ref(document.documentElement.clientWidth);
const isPhone = screenWidth.value < 993;
onMounted(() => {
  if (isPhone) {
    headerStyle.value = {
      height: "3.5vh",
    };
    let items = Array.from(document.getElementsByClassName("el-menu-item"));
    items.shift();
    items.forEach((item) => {
      (item as HTMLElement).style.fontSize = "1.5vw";
    });
    (
      document.getElementsByClassName("el-dropdown-link")[0] as HTMLElement
    ).style.fontSize = "1.5vw";
  }
  proxy.$bus.on("jump", (inx: number) => {
    selectInx.value = inx + "";
  });
});
let selectMenu = (e: any) => {
  selectInx.value = e + "";
};
const setLang = (e: number) => {
  localStorage.setItem("language", langList.value[e].lang);
  locale.value = langList.value[e].lang;
};
</script>

<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100%;
}
.logo {
  width: auto;
  display: flex;
  align-items: center;
  cursor: default;
  span {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 2vw;
    color: #ffffff;
    margin-left: 8px;
  }
}
.header {
  background: #222;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &_left {
    margin-right: 2vw;
    display: flex;
    align-items: center;
    ::v-deep(.el-dropdown) {
      color: #ffffff;
      .el-dropdown-link {
        display: flex;
        font-size: 1vw;
      }
    }
    ::v-deep(.el-dropdown):hover {
      cursor: default;
    }
  }
}
::v-deep(.el-menu) {
  height: inherit;
  .el-menu-item {
    font-size: 1vw;
    padding: 0 2vw !important;
  }
  .el-menu-item:nth-child(1) {
    border: none !important;
  }
}
.footer {
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 35px !important;
  padding-top: 12% !important;
  position: relative;
  img {
    width: 11vw;
    position: absolute;
    top: -10%;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.text {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 1.5vw;
  color: #ffffff;
}
</style>
