
export default {
  message: {
    more: 'more',
    name: 'Zhunxingda',
    lang: 'English',
    wait: 'Please waiting',
    homeLang: {
      menuItem1: 'Our strengths',
      menuItem2: 'Solution',
      menuItem3: 'Product Services',
      menuItem4: 'customer cases'
    },
    compLang: {
      slogan: 'We are committed to becoming a leading, small and refined online freight matching platform in the northwest region; focusing on achieving a win-win situation for both shippers and drivers; providing shippers with accurate driver positioning and matching the best quality supply for drivers, jointly improving logistics efficiency and transportation value',
      btnText: 'Contact Us',
      menuItem1Cont: 'Small-but-excellen',
      menuItem2Cont: 'Deliver precise information on supply sources and vehicle locations',
      menuItem3Cont: 'Software + 24-hour customer service',
      menuItem4Cont: 'Recognition from cargo owners and drivers',
      newMenuItem4: 'Customer feedback'
      // Customer feedback
    }
  }
}