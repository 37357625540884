
export default {
  message: {
    more: '了解更多',
    name: '准行达',
    lang: '中文（简体）',
    wait: '敬请期待',
    homeLang: {
      menuItem1: '我们的优势',
      menuItem2: '解决方案',
      menuItem3: '产品服务',
      menuItem4: '客户案例'
    },
    compLang: {
      slogan: '致力于做一家西北地区领先且小而精的线上货运撮合平台；专注于实现货主与司机的双赢；为货主提供精准的司机定位，为司机匹配最优质货源，共同提升物流效率与运输价值',
      btnText: '联系我们',
      menuItem1Cont: '小而精',
      menuItem2Cont: '提供精准的货源信息和车辆位置信息',
      menuItem3Cont: '软件+24小时客服',
      menuItem4Cont: '来自货主和司机的认可',
      newMenuItem4: '客户反馈'
    }
  }
}