import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import index from "../views/homeChild/homeComp.vue"
import wait from "../views/homeChild/waitView.vue"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        name: "index",
        path: "index",
        component: index
      },
      {
        name: "wait",
        path: "wait",
        component: wait
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to: any, from: any, next: any) => {
  // useI18n().locale.value = localStorage.getItem('language') || 'zh'
  if (to.path == "/") {
    console.log(to.path, from.path);
    // proxy.$bus.emit('')
    next('/index')
  } else {
    next()
  }
})

export default router;
